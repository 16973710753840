<template>
  <div class="h-screen flex w-full bg-img no-gutter items-center justify-center">
    <div v-if="!pageLoading" class="flex justify-center sm:w-full lg:w-2/3 xl:w-3/5 xxl:w-2/5 self-center sm:m-0 m-4">
      <vx-card class="w-full sm:w-1/2">
        <div slot="no-body" class="w-full">
          <div class="vx-row no-gutter rounded-md">
            <!-- <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img src="@/assets/images/pages/login.png" alt="login" class="mx-auto" />
            </div> -->
            <div v-if="!accountDeleted" class="w-full">
              <div v-if="!actionCodeInvalid">
                <div v-if="mode === 'resetPassword'" class="p-8">
                  <div v-if="!passwordUpdated" class="vx-card__title mb-8">
                    <h4 class="mb-4">Enter a new password</h4>
                    <p> Please enter a new password for your account </p>
                  </div>
                  <div v-else class="vx-card__title mb-8 text-center">
                    <h4 class="mb-4">Password successfully reset!</h4>
                    <p> You can now login in with your new password.</p>
                    <br />
                    <p>
                      <strong>Guides</strong>
                      return to the mobile app to login.</p
                    >

                    <br />
                    <p><strong>Employers</strong> select 'Back to Login' below.</p>
                    <!-- <p v-else> You can now login in to the Recruit a Guide app with your new password.</p> -->
                  </div>
                  <div v-if="!passwordUpdated">
                    <vs-input v-model="password" type="password" label-placeholder="Password" class="w-full mb-6" />

                    <vs-input
                      v-model="confirmPassword"
                      type="password"
                      label-placeholder="Confirm password"
                      :class="passwordMismatch ? 'w-full mb-4' : 'w-full mb-8'"
                    />
                  </div>

                  <div v-if="passwordMismatch" class="mb-4" style="color: red"> Your passwords do not match </div>
                  <div v-if="!passwordUpdated">
                    <!-- <vs-button type="border" to="/pages/login" class="px-4 w-full md:w-auto">Back To Login</vs-button> -->
                    <vs-button v-if="!savingPassword" class="px-4 w-full mt-3 mb-8 md:mt-0 md:mb-0" @click="savePassword(password)"
                      >Save Password</vs-button
                    >
                    <vs-button v-else disabled class="px-4 w-full mt-3 mb-8 md:mt-0 md:mb-0">Saving...</vs-button>
                    <p class="text-sm mt-3 text-center"
                      >I am not supposed to be here.
                      <span class="text-rag-green-dark cursor-pointer hover:underline" @click="toLogin">To login</span></p
                    >
                  </div>
                  <vs-button v-if="passwordUpdated" to="/pages/login" class="px-4 w-full">Back To Login</vs-button>
                </div>
                <div v-if="mode === 'verifyEmail'" class="p-8">
                  <div v-if="continueUrl !== 'https://recruitaguide.page.link'">
                    <div class="text-center vx-card__title">
                      <h4 class="mb-4">Email successfully verified!</h4>
                      <p v-if="!fbUser"> You can now login into your account.</p>
                      <p v-else>Please enjoy Recruit a Guide!</p>

                      <vs-button v-if="!fbUser" to="/pages/login" class="px-4 mt-5 w-full">Login</vs-button>
                      <vs-button v-else class="px-4 mt-5 w-full" @click="toDashboard">Continue to Recruit a Guide</vs-button></div
                    >
                  </div>
                  <div v-else>
                    <div class="vx-card__title my-8 text-center">
                      <h4 class="mb-4">Email successfully verified!</h4>
                      <p> You can now login into the Recruit a Guide app.</p>
                    </div>
                  </div>
                </div>
                <div v-if="mode === 'recoverEmail'" class="p-8">
                  <div>
                    <div class="vx-card__title text-center">
                      <h4 class="mb-4">Email successfully reverted!</h4>
                      <p>
                        You can now login into your account with the email address <strong>{{ restoredEmail }}</strong
                        >.</p
                      >

                      <vs-button v-if="!fbUser" to="/pages/login" class="px-4 mt-5 w-full">Login</vs-button>
                      <vs-button v-else class="px-4 mt-5 w-full" @click="toDashboard">Continue to Recruit a Guide</vs-button></div
                    >
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="p-8">
                  <div>
                    <div class="vx-card__title text-center">
                      <h4 class="mb-4">Something went wrong...</h4>
                      <p> This link has been used or is expired.</p>

                      <vs-button v-if="!fbUser" to="/pages/login" class="px-4 mt-5 w-full">Back to login</vs-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="w-full">
              <div class="p-8">
                <div>
                  <div class="vx-card__title text-center">
                    <h4 class="mb-4">Your account has been deleted</h4>
                    <p> We are sorry to see you go and hope you'll give Recruit a Guide another chance in the future.</p>

                    <vs-button to="/pages/login" class="px-4 mt-5 w-full">Back to login</vs-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
// import firebase from 'firebase/compat/app';
import { getAuth, applyActionCode, checkActionCode } from 'firebase/auth';
export default {
  data() {
    return {
      password: '',
      confirmPassword: '',
      mode: '',
      oobCode: '',
      continueUrl: '',
      passwordMismatch: false,
      savingPassword: false,
      passwordUpdated: false,
      isGuide: false,
      isEmployer: false,
      emailReverted: false,
      restoredEmail: '',
      actionCodeInvalid: false,
      pageLoading: true,
      accountDeleted: this.$route.params.mode === 'account-deleted' ? true : false,
    };
  },
  computed: {
    fbUser() {
      const auth = getAuth();
      return auth.currentUser;
    },
  },
  created() {
    this.mode = this.getParameterByName('mode');
    if (!this.mode && this.$route.params.mode !== 'account-deleted') {
      this.$router.push('/pages/login');
    }
    this.oobCode = this.getParameterByName('oobCode');
    this.continueUrl = this.getParameterByName('continueUrl');
    if (this.mode === 'verifyEmail') {
      this.handleVerifyEmail();
    }
    if (this.mode === 'recoverEmail') {
      this.handleRecoverEmail();
    }
    if (this.mode === 'resetPassword' || this.$route.params.mode === 'account-deleted') {
      this.pageLoading = false;
    }
  },

  methods: {
    toLogin() {
      this.$router.push('/pages/login');
    },
    async toDashboard() {
      this.$vs.loading({ type: 'corners' });
      const auth = getAuth();
      await auth.currentUser.reload();
      this.$router.push('/pages/resume');
      this.$vs.loading.close();
    },

    handleRecoverEmail() {
      this.$vs.loading({ type: 'corners' });
      const auth = getAuth();
      // Confirm the action code is valid.
      checkActionCode(auth, this.oobCode)
        .then((info) => {
          // Get the restored email address.
          this.restoredEmail = info['data']['email'];
          // let previousEmail = info['data']['previousEmail'];
          // Revert to the old email.
          return applyActionCode(auth, this.oobCode);
          // .then(async () => {
          //   const employerData = await firebase.firestore().collection('employers').where('email', '==', previousEmail).get();
          //   console.log(employerData.docs[0]);
          //   await firebase.firestore().collection('employers').doc(employerData.docs[0].id).update({ email: this.restoredEmail });
          // });
        })
        .then(() => {
          this.$vs.loading.close();
          this.pageLoading = false;
        })
        .catch((error) => {
          if (error.message === 'Firebase: Error (auth/invalid-action-code).') {
            this.actionCodeInvalid = true;
          }
          this.pageLoading = false;
          this.$vs.loading.close();
        });
    },

    handleVerifyEmail() {
      // this.$vs.loading({ type: 'corners' });
      const auth = getAuth();
      applyActionCode(auth, this.oobCode)
        .then(() => {
          this.$vs.loading.close();
          this.pageLoading = false;
          return 'User verified';
        })
        .catch((error) => {
          this.pageLoading = false;
          if (error.message === 'Firebase: Error (auth/invalid-action-code).') {
            this.actionCodeInvalid = true;
          }
          this.$vs.loading.close();
        });
    },
    getParameterByName(name, url) {
      var urls = url;
      if (!url) urls = window.location.href;
      // eslint-disable-next-line no-useless-escape
      var names = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + names + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(urls);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, ' '));
    },
    async savePassword(password) {
      if (this.password === this.confirmPassword) {
        this.savingPassword = true;

        this.passwordMismatch = false;
        const payload = {
          notify: this.$vs.notify,
          password: password,
          oobCode: this.oobCode,
        };
        this.$store
          .dispatch('auth/resetPassword', payload)
          .then(async () => {
            this.password = '';
            this.confirmPassword = '';
            this.savingPassword = false;
            this.passwordUpdated = true;

            // let isEmp = await firebase.firestore().collection('employers').where('email', '==', email).get();
            // if (isEmp.docs.length === 0) {
            //   this.isGuide = true;
            // } else {
            //   this.isEmployer = true;
            // }
            // if (this.isEmployer) {
            //   this.$router.push('/pages/login');
            // }
          })
          .catch(() => {
            this.savingPassword = false;
          });
      } else {
        this.passwordMismatch = true;
      }
    },
  },
};
</script>
